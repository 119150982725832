import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Gemeente Vlaanderen',
        title: 'A roaring new custom design system with a focus on accessibility',
        url: 'gemeente-vlaanderen'
    },
    {
        image: 'image-2',
        category: 'Next Sense',
        title: 'Branding the next generation of real estate software',
        url: 'next-sense'
    },
    {
        image: 'image-3',
        category: 'Nash',
        title: 'Good design work can make even cryptocurrency feel simple',
        url: 'nash-io'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    {/*<p>{value.category}</p>*/}
                                    <h4><a href={`${value.url}`}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        {/*<a className="rn-btn" href="/portfolio-details">View Details</a>*/}
                                        <a className="rn-btn" href={value.url}>View Project</a>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to={`${value.url}`}></Link>
                            {/*<Link className="link-overlay" to={`${value.url}`}></Link>*/}
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;