import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import HeaderTwo from "../component/header/HeaderProject";
import ContactOne from "./contact/ContactOne";
import Footer from "../component/footer/FooterTwo";

class VlaanderenProject extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Samuel Scheelings - Product and UX Designer' />
                <HeaderTwo logo="symbol-dark" color="color-white" />
                {/*<Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />*/}
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient color-white">A roaring custom design system with a focus on accessibility</h2>
                                    {/*<p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Gemeente Vlaanderen</h2>
                                        <p className="subtitle">Development of a comprehensive design system for the municipality of Vlaanderen. This design system ensures accessibility across all digital platforms, employing inclusive design principles to improve the user experience and usability for all users.</p>

                                        <h3><span role="img" aria-label="Clock icon" className="emoji-icon">🕔</span> TL DR;</h3>
                                        <ul className="border-bottom pb--20 font--18">
                                            <li>Created a design system with a focus on accessibility adhering to WCAG 2.1 AA / AAA </li>
                                            <li>Expanded on designer to developer workflow across products </li>
                                            <li>Translated product briefs and user journeys to visual designs </li>
                                        </ul>

                                        <div className="portfolio-view-list d-flex flex-wrap pb--40">
                                            <div className="port-view pb--20">
                                                <span><span role="img" aria-label="Document icon" className="emoji-icon">📃</span> Responsibilties </span>
                                                <h4>Product Design - UX Design</h4>
                                            </div>

                                            <div className="port-view pb--20">
                                                <span><span role="img" aria-label="Trophy icon" className="emoji-icon">🏆</span> Achievements </span>
                                                <h4>Developed a comprehensive design system to ensure accessibility across all digital platforms - Implemented inclusive design principles to cater to a diverse audience - Improved developer handoff with StorybookJS - Setup a company wide style guide to be used across products</h4>
                                            </div>

                                            <div className="port-view pb--20">
                                                <span><span role="img" aria-label="Handshake icon" className="emoji-icon">🤝</span> Together with </span>
                                                <h4>Engineering - Product - Marketing</h4>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="inner mt--40">
                                        <h3>Small elements, big changes <span role="img" aria-label="Atom emoji">⚛️</span></h3>
                                        <p>The project began with an in-depth analysis of the municipality's existing digital platforms and their accessibility features. Following this analysis, I collaborated closely with stakeholders to establish design requirements and goals.</p>
                                        <p>I went for an atomic design approach which could easily be combined with the MUI in place by engineering.</p>
                                    </div>


                                    <div className="row justify-content-center">
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/atomic.png" alt="Atomic design breakdown"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="inner border-top">
                                        <h3 className="pt--40">New coat of paint.. and a lot more! <span role="img" aria-label="Paint emoji">🎨</span> </h3>
                                        <p>This comprehensive system was meticulously crafted to ensure that all digital platforms are inclusive and user-friendly.</p>
                                        <p>Together with Engineering I made sure the components adhered to the WCAG 2.1 AA/AAA standards.</p>
                                        <p>The design foundation was created in Figma using variables and modes. To make working with components efficient, multiple components got custom properties for easy configuration!</p>

                                        <div className="row justify-content-center">


                                        <div className="portfolio-thumb-inner col-lg-12 ">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/props.png" alt="Custom Figma properties"/>
                                            </div>
                                        </div>
                                        </div>



                                    </div>
                                    <div className="row">
                                        <div className="portfolio-thumb-inner col-lg-12">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/cover.png" alt="AU Design cover"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/vlaanderen1.png" alt="Design system images"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/vlaanderen2.png" alt="Design system images"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/vlaanderen3.png" alt="Design system images"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/vlaanderen5.png" alt="Design system images"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-12">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/vlaanderen4.png" alt="Design system images"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="inner border-top">
                                        <h3 className="pt--40">Document Management <span role="img" aria-label="Document emoji">📃 </span> </h3>
                                        <p>Gelinkt Notuleren is a product used to manage document templates and internal variables. </p>
                                        <p>Objectives were to create new components, view existing ones and their usage.</p>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="portfolio-thumb-inner col-lg-3">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/gn1.png" alt="AU Design cover"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-3">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/gn2.png" alt="Design system images"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-3">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/gn3.png" alt="Design system images"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-3">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/gn4.png" alt="Design system images"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="inner border-top">
                                        <h3 className="pt--40">Browsing Associations <span role="img" aria-label="Office building emoji">🏢 </span> </h3>
                                        <p>Organisatie Portaal is a product used to browse through the associations database and if the user role permits, make edits and register new entries. </p>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="portfolio-thumb-inner col-lg-4">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/op1.png" alt="AU Design cover"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-4">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/op2.png" alt="Design system images"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-4">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/vlaanderen/op3.png" alt="Design system images"/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Hey, check out my other work!</span>
                                    <h2>Other Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/nash-io">
                                            <img src="/assets/images/portfolio/related-image-nash.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/nash-io">Nash Crypto Trading</a></h4>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/next-sense">
                                            <img src="/assets/images/portfolio/related-image-nextsense.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/next-sense">Next Sense</a></h4>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                <ContactOne />
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default VlaanderenProject;
