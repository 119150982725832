import React, { Component } from "react";
import {FaPhone , FaLinkedinIn, FaEnvelope} from "react-icons/fa";

class ContactOne extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }
    render(){
        return(
            <div className="contact-form--1 pb--100" id="contact">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--50">
                                <h2 className="title">Get in touch with me.</h2>
                                <p className="description">Feel free to get in touch with me about my work or to discuss superheroes.
                                </p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row justify-content-md-center">
                                <div className="col-lg-3 text-center">
                                    <div className="contactTile mb--20">
                                        <a href="tel:0031636430451" className="pt--40 pb--40">
                                            <FaPhone className="contactIcon" /> <br/>
                                            <a href="tel:0031636430451">+31(0)6 36 43 04 51</a>
                                        </a>

                                    </div>
                                </div>
                                <div className="col-lg-3 text-center">
                                    <div className="contactTile mb--20">
                                        <a href="https://www.linkedin.com/in/samscheelings/" className="pt--40 pb--40">
                                            <FaLinkedinIn className="contactIcon" /> <br/>
                                            <a href="https://www.linkedin.com/in/samscheelings/" target="_blank" rel="noopener noreferrer">My LinkedIn</a>
                                        </a>

                                    </div>
                                </div>
                                <div className="col-lg-3 text-center">
                                    <div className="contactTile mb--20">
                                        <a href="mailto:sam@justcallmesam.net" className="pt--40 pb--40">
                                            <FaEnvelope className="contactIcon" /> <br/>
                                            <a href="mailto:sam@justcallmesam.net">sam@justcallmesam.net</a>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactOne;