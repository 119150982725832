// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import PersonalPortfolio from './home/PersonalPortfolio';

// Element Layout
import error404 from "./elements/error404";
import VlaanderenProject from "./elements/VlaanderenProject";
import NextSenseProject from "./elements/NextSenseProject";
// import EdgeNextProject from "./elements/EdgeNextProject";
import NashProject from "./elements/NashProject";
// import NEXProject from "./elements/NEXProject";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={PersonalPortfolio}/>
                        {/*<Route exact path={`${process.env.PUBLIC_URL}/edge-next`} component={EdgeNextProject}/>*/}
                        <Route exact path={`${process.env.PUBLIC_URL}/gemeente-vlaanderen`} component={VlaanderenProject}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/next-sense`} component={NextSenseProject}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/nash-io`} component={NashProject}/>
                        {/*<Route exact path={`${process.env.PUBLIC_URL}/nex`} component={NEXProject}/>*/}

                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>

                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();