import React from 'react';
// import {FaPhone , FaLinkedinIn, FaEnvelope} from "react-icons/fa";

// const SocialShare = [
//     {Social: <FaPhone /> , link: 'tel:+31636430451'},
//     {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
//     {Social: <FaEnvelope /> , link: 'mailto:sam@justcallmesam.net'}
// ]

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30" data-black-overlay="6">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner">
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="text">
                                <p>Copyright © 2024 Samuel Scheelings</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;