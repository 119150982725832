import React, { Component , Fragment } from "react";
import HeaderTwo from "../component/header/HeaderTwo";
import FooterTwo from "../component/footer/FooterTwo"
import PortfolioList from "../elements/portfolio/PortfolioList";
import TabTwo from "../elements/tab/TabTwo";
import ContactOne from "../elements/contact/ContactOne";
import Helmet from "../component/common/Helmet";



const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        title: 'Hi, name is <span>Samuel</span><br> I&apos;m a Product and UX Designer',
        description: 'But just call me Sam 😉',
        buttonText: 'Download my CV',
        buttonLink: '/assets/files/CV_SamScheelings.pdf'
    }
]
class PersonalPortfolio extends Component{
    render(){
        let title = 'About Me'
        return(
            <Fragment> 
                <Helmet pageTitle="Personal Portfolio" />
                
                <HeaderTwo logo="symbol-dark" color="color-black"/>
                
                {/* Start Slider Area   */}
                <div className="slider-wrapper" id="top">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--16" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title" dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}
                                            {/*{value.description ? <p className="description pt--20 pb--30">{value.description}</p> : ''}*/}
                                            {value.description ? <p className="description pt--20 pb--30">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a rel="noopener noreferrer" target="_blank" download className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120 bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail" id="about">
                                        <img className="w-100" src="/assets/images/about/about-5.jpg" alt="Samuel Scheelings"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">About Sam</h2>
                                            <p className="description">
                                                <p>I'm a self-taught designer with over 14 years of experience solving complex problems, with simple design solutions.</p>
                                                <p>I have a passion for all things design. From building apps, websites, dashboards and marketplaces, to experimenting with a little front-end development.</p>
                                            </p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--60 bg_color--1" id="work">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">My Latest Projects</h2>
                                        <p>A pixel says more than a thousand words.. wait.. I don't think I got that right.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="3" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Whimsies */}
                <div className="portfolio-area pb--60 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Chasing Whimsies</h2>
                                        <p>I like to design random things for fun sometimes!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container pb--80 border-bottom mt--20">
                                <div className="row text-center">
                                    <div className="col-lg-3 mb--20">
                                        <img className="whimsiePanel" src="/assets/images/whimsies/1.png" alt="Seinfeld shirt"/>
                                    </div>
                                    <div className="col-lg-3 mb--20">
                                        <img className="whimsiePanel" src="/assets/images/whimsies/2.png" alt="Door Knockers - Terrify. Bat"/>
                                    </div>
                                    <div className="col-lg-3 mb--20">
                                        <img className="whimsiePanel" src="/assets/images/whimsies/3.png" alt="MF DOOM digital painting"/>
                                    </div>
                                    <div className="col-lg-3 mb--20">
                                        <img className="whimsiePanel" src="/assets/images/whimsies/4.png" alt="Arrested Apparel shirts"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Whimsies */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area bg_color--1">
                    <ContactOne />
                </div>
                {/* End Portfolio Area */}
                <FooterTwo />
            </Fragment>
        )
    }
}

export default PersonalPortfolio;