import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import HeaderTwo from "../component/header/HeaderProject";
import ContactOne from "./contact/ContactOne";
import Footer from "../component/footer/FooterTwo";

class NextSenseProject extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Samuel Scheelings - Product and UX Designer' />
                <HeaderTwo logo="symbol-dark" color="color-white" />
                {/*<Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />*/}

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--2"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient color-white">Branding the next generation of real estate software</h2>
                                    {/*<p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Next Sense</h2>
                                        <p className="subtitle">Next Sense uses smart technologies to effectively decarbonize commercial real estate. Their platform uses data, simulations and AI control to slash CO2 footprints in compliance with ESG benchmarks.</p>

                                        <h3><span role="img" aria-label="Clock icon" className="emoji-icon">🕔</span> TL DR;</h3>
                                        <ul className="border-bottom pb--20 font--18">
                                            <li>Designed a unique and memorable brand identity, including a logo, color palette, typography, and brand guidelines</li>
                                            <li>Implemented the new branding across various touchpoints including social media, email communication and internal documents</li>
                                        </ul>

                                        <div className="portfolio-view-list d-flex flex-wrap pb--40">
                                            <div className="port-view pb--20">
                                                <span><span role="img" aria-label="Document icon" className="emoji-icon">📃</span> Responsibilties </span>
                                                <h4>Brand Identity Design - Marketing Material Design - Product UI Design</h4>
                                            </div>

                                            <div className="port-view pb--20">
                                                <span><span role="img" aria-label="Trophy icon" className="emoji-icon">🏆</span> Achievements </span>
                                                <h4>Established a clear brand positioning and messaging framework - Identified opportunities for brand differentiation and market positioning - Created a visually appealing and memorable brand identity, including a logo, color palette, typography, and visual elements </h4>
                                            </div>

                                            <div className="port-view pb--20">
                                                <span><span role="img" aria-label="Handshake icon" className="emoji-icon">🤝</span> Together with </span>
                                                <h4>Senior Management - Marketing - Sales - Operations  </h4>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="inner mt--40">
                                        <h3>The potential in synergy <span role="img" aria-label="Plant icon" className="emoji-icon">🌿</span></h3>
                                        <p>Next Sense combines the human factor with the latest technologies to decarbonize real estate.</p>
                                        <p>From the initial concept to the final design, my goal was to create a brand that not only stood out in a competitive market but also reflected the commitment to building a more sustainable future.</p>
                                    </div>
                                    {/*<div className="portfolio-thumb-inner">*/}
                                    {/*    <div className="thumb mb--30">*/}
                                    {/*        <img src="/assets/images/portfolio/next/ns-total.png" alt="Portfolio Images"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="inner border-top">
                                        <h3 className="pt--40">The visual system </h3>

                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/grid1.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/grid2.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/grid3.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/grid4.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/grid5.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/grid6.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inner border-top">
                                        <h3 className="pt--40">Typography </h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/type1.png" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/type2.png" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/type3.png" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/type4.png" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inner border-top">
                                        <h3 className="pt--40">Colors </h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/colors1.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/colors2.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/colors3.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/colors4.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inner border-top">
                                        <h3 className="pt--40">Logo </h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/logo1.png" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/logo2.png" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/logo3.png" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/logo4.png" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inner border-top">
                                        <h3 className="pt--40">Putting it all together </h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/mock1.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/mock2.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/mock3.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 portfolio-thumb-inner">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/next/mock4.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Hey, check out my other work!</span>
                                    <h2>Other Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/gemeente-vlaanderen">
                                            <img src="/assets/images/portfolio/related-image-vlaanderen.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/gemeente-vlaanderen">Gemeente Vlaanderen</a></h4>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/nash-io">
                                            <img src="/assets/images/portfolio/related-image-nash.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/nash-io">Nash Crypto Trading</a></h4>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                <ContactOne />

                <Footer />


            </React.Fragment>
        )
    }
}
export default NextSenseProject;
