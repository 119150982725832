import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import HeaderTwo from "../component/header/HeaderProject";
import ContactOne from "./contact/ContactOne";
import Footer from "../component/footer/FooterTwo";

class NashProject extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Samuel Scheelings - Product and UX Designer' />
                <HeaderTwo logo="symbol-dark" color="color-white" />
                {/*<Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />*/}

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--3"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient color-white">Good design work can make even cryptocurrency feel simple</h2>
                                    {/*<p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Nash.io</h2>
                                        <p className="subtitle">Cryptocurrency can be difficult to understand and even scarier to interact with. Using design to make trading crypto available for the novice and advanced traders. </p>

                                        <h3><span role="img" aria-label="Clock icon" className="emoji-icon">🕔</span> TL DR;</h3>
                                        <ul className="border-bottom pb--20 font--18">
                                            <li>Conducted user interviews and collected external data to identify in the crypto and payments landscape</li>
                                            <li>Designed everything from a cryptocurrency exchange, corporate website and marketing assets. Including a sponsored stand at the EU Parliament</li>
                                        </ul>

                                        <div className="portfolio-view-list d-flex flex-wrap pb--40">
                                            <div className="port-view pb--20">
                                                <span><span role="img" aria-label="Document icon" className="emoji-icon">📃</span> Responsibilties </span>
                                                <h4>Product Design - UX Design & Research - Persona Creation - (Remote) User Testing</h4>
                                            </div>

                                            <div className="port-view pb--20">
                                                <span><span role="img" aria-label="Trophy icon" className="emoji-icon">🏆</span> Achievements </span>
                                                <h4>Over $750k sustained volume first quarter - 88% Maintained account adoption >6 months </h4>
                                            </div>

                                            <div className="port-view pb--20">
                                                <span><span role="img" aria-label="Handshake icon" className="emoji-icon">🤝</span> Together with </span>
                                                <h4>Brand Designer - Front-end Development - Marketing </h4>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="inner mt--40">
                                        <h3>User Research & Persona's <span role="img" aria-label="Person icon" className="emoji-icon">🙋‍</span></h3>
                                        <p>For user research it helped this project was built on a big OpenSource community. I crafted a survey which was sent out across different social media channels such as Reddit, Twitter and Facebook.</p>
                                        <p>I used this survey to define demographics, purchasing interest and competitive analysis. These results helped craft our initial persona's which helped to define goals and user flows for the product MVP.</p>
                                    </div>

                                    <div className="inner">
                                        <p className="text-center">
                                            <a className="rn-button-style--2 btn-primary-color text-center" href="/assets/images/portfolio/nash/NashSurveyExport.pdf" target="_blank">Download PDF deck</a>
                                        </p>
                                    </div>
                                    <div className="inner border-top">
                                        <h3 className="pt--40">User Flows - Simplified Trading <span role="img" aria-label="Chart icon" className="emoji-icon">📈 </span></h3>
                                        <p>During the entire design process, I created user flows to not only maintain my own sanity but also to clearly map out our different user scenario's.</p>
                                        <p>User research had shown some people we're quickly to be overwhelmed by too advanced interfaces. Part of our MVP was to offer a very simplified trading UI.</p>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/nash/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div>
                                    </div>

                                    <div className="inner border-top">
                                        <h3 className="pt--40">Creating the LEGO bricks <span role="img" aria-label="Brick icon" className="emoji-icon">🧱</span></h3>
                                        <p>To optimize my design workflow and guarantee consistency across the UI and products I created a DMS (Design Management System). This also helped our front-end developers get the building blocks for our products ready and make for a more frictionless development process.</p>
                                    </div>
                                    <div className="row">
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/nash/portfolio-big-04.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/nash/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="inner border-top">
                                        <h3 className="pt--40">Wireframes and Prototyping - Simplified Trading </h3>
                                        <p>To really make cryptocurrency understandable for the masses we wanted to accomodate users new to crypto trading. Mainly based on Alex, our entry level persona with a simplified trading interface.</p>
                                        <p>Combined with the created user flows I turned to quickly mocking up the product interface ideas, conversing with the product team and (closed) user testing.</p>
                                        <p>I communicated with Engineering during the process and the later wireframes to make sure development handoff was smoothly.</p>
                                    </div>
                                    <div className="row">
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/nash/portfolio-big-05.jpg" alt="Simplified trading wireframes"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/nash/portfolio-big-06.jpg" alt="Simplified trading wireframes"/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="inner border-top">
                                        <h3 className="pt--40">Polishing - Simplified Trading</h3>
                                    </div>
                                    <div className="row">
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/nash/portfolio-big-07.jpg" alt="Simplified trading design"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/nash/portfolio-big-08.jpg" alt="Simplified trading design"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/nash/portfolio-big-09.jpg" alt="Simplified trading design"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/nash/portfolio-big-10.jpg" alt="Simplified trading design"/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="inner border-top">
                                        <h3 className="pt--40">Trading. As you want it.</h3>
                                        <p>To service more trade savvy users using our Exchange product, we required an advanced exchange.</p>
                                        <p>Following the same workflow with wireframing, communicating and prototyping I created the initial advance exchange interface.</p>
                                        <p>I based my UI design on conventions already found in crypto trading. Again, our user research and survey gave us a good direction what to look for and perform a proper competitor analysis.</p>
                                        <p>As a little bonus, I designed a dark theme to ease the eyes of late night traders. Because as we all know, the market never sleeps. <span role="img" aria-label="Moon emoji">🌕</span> <span role="img" aria-label="Chart up emoji">📈</span> </p>
                                    </div>
                                    <div className="row">
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/nash/portfolio-big-11.jpg" alt="Simplified trading wireframes"/>
                                            </div>
                                        </div>
                                        <div className="portfolio-thumb-inner col-lg-6">
                                            <div className="thumb mb--30">
                                                <img src="/assets/images/portfolio/nash/portfolio-big-12.jpg" alt="Simplified trading wireframes"/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Hey, check out my other work!</span>
                                    <h2>Other Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/gemeente-vlaanderen">
                                            <img src="/assets/images/portfolio/related-image-vlaanderen.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/gemeente-vlaanderen">Gemeente Vlaanderen</a></h4>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/next-sense">
                                            <img src="/assets/images/portfolio/related-image-nextsense.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/next-sense">Next Sense</a></h4>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                <ContactOne />

                <Footer />


            </React.Fragment>
        )
    }
}
export default NashProject;
